/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link} from 'react-router-dom';
import Slider from "react-slick";

import { stripslashes } from "../../Helpers/SettingHelper";
import homeBannerImg from "../../../common/images/home-banner.jpg";
import { GET_BANNER_LIST } from '../../../actions';

import { languageCd } from "../../Helpers/Config";
const lang = require('../../Helpers/Language/lang'+'-'+languageCd);


var Parser = require('html-react-parser');

class HomeBanner extends Component {
	  constructor(props) {
		super(props);
	  }
	  
	  componentWillMount() {
		this.props.getBannerList();
	  }
	  
	  componentDidMount() {
		/*console.log('DidMount');*/
	  }
	  
	  /* componentDidUpdate() {
		console.log('this called last - after page loading');
	  } */
	  
	  render() {
		  
		let bannerArr = this.props.banner;
		let bannerlist = [];
		let bannerimagesource = '';

		if(Object.keys(bannerArr).length > 0) {
			if(bannerArr[0].status === 'ok') {
				bannerlist = bannerArr[0].result_set;
				bannerimagesource = bannerArr[0].common.image_source;
			}
		}  
		  
		var settingsGallery = {
			infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1
        };
		
		return (
			    <div className="home-banner">
					{(Object.keys(bannerlist).length > 0)?<Slider {...settingsGallery}>
						   {
							  (bannerlist).map(
							  (banner,index) => {
								  return (
									<div key={index}>
										<img src={bannerimagesource + banner.banner_image} alt="Banner" />
											{/*<div className="banner-info">
											<h2>{stripslashes(banner.banner_name)}</h2>
											<p>{(banner.banner_description!='')?Parser(stripslashes(banner.banner_description)):''}</p>
											</div>*/}  
									</div>
								  )
								 }
								) 
						   }
					</Slider>:
					<div className="loader-main-cls">
						<img src={homeBannerImg} alt="Banner" />
						<div className="loader-sub-div"></div>
					</div>}
					
					{/*(Object.keys(bannerlist).length > 0) && <div className="fixed-banner-caption">
					  <div className="container">
						<div className="fixed-banner-lhs"> 
							<h1>{lang.homebannerinfo.title} </h1>
							<h3>{lang.homebannerinfo.subtitle} </h3>
						</div>
						<div className="fixed-banner-rhs"> 
							<a className="button" href="/products">{lang.homebannerinfo.orderNow}</a>
						</div>
					  </div>
					</div>*/}
					
				</div>
					
			   )
	  }	
}

const mapStateTopProps = (state) => {
  return {
    banner: state.banner
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getBannerList: () => {
      dispatch({ type: GET_BANNER_LIST });
    },
  }
}

export default connect(mapStateTopProps, mapDispatchToProps)(HomeBanner);
