/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link} from 'react-router-dom';
import Slider from "react-slick";

import { stripslashes } from "../../Helpers/SettingHelper";
import { languageCd } from "../../Helpers/Config";
import homeBannerImg from "../../../common/images/home-banner.jpg";

var Parser = require('html-react-parser');

class StaticBanner extends Component {
	  constructor(props) {
		super(props);
	  }
	  
	  componentDidMount() {
		
	  }
	  
	  getBannerlist() {
		  var enLftBnr = '', enRgtBnr = '';
		  if(Object.keys(this.props.staticBlk).length > 0) {
			this.props.staticBlk.map((data, index) => {
			  if(data.staticblocks_slug === 'static-banner-left') {
				  enLftBnr = data.staticblocks_description;
			  }
			  if(data.staticblocks_slug === 'static-banner-right') {
				  enRgtBnr = data.staticblocks_description;
			  }
			});
		  }
		  enLftBnr = (enLftBnr !== '') ? Parser(enLftBnr) : enLftBnr;
		  enRgtBnr = (enRgtBnr !== '') ? Parser(enRgtBnr) : enRgtBnr;
		  
		 /* if(languageCd == 'en') { */
			  if(this.props.bannerFlag === 'left' && enLftBnr !== '') {
					  var stcLeftBnr = {
								infinite: true,
								arrows: false,
								dots:true,
								dotsClass: 'custom-dots',
								slidesToShow: 1,
								slidesToScroll: 1
						  };
					  
					  return (<Slider {...stcLeftBnr}>
								{enLftBnr}
							 </Slider>);
			  }
			  
			  if(this.props.bannerFlag === 'right' && enRgtBnr !== '') {
					  var stcRgttBnr = {
								infinite: true,
								arrows: false,
								dots:true,
								dotsClass: 'custom-dots',
								slidesToShow: 1,
								slidesToScroll: 1
						  };
					  
					  return (<Slider {...stcRgttBnr}>
								{enRgtBnr}
							 </Slider>);
			  }
		/* } */
	  }
	  
	  render() {
		
		return (
			    <div className="static-banner">
				{this.getBannerlist()}	
				</div>
			   )
	  }	
}

export default (StaticBanner);
