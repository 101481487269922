/* eslint-disable */
import React, { Component } from 'react';
//import validator from 'validator'
/*import update from 'immutability-helper'*/
import { validated } from 'react-custom-validation';
import { languageCd } from "../Helpers/Config";
const lang = require('../Helpers/Language/lang'+'-'+languageCd);

function validationConfigPassword(props) {
    const { oldpassword, newpassword, confirmpassword } = props.fields
    return {
        fields: ['oldpassword', 'newpassword', 'confirmpassword'],
        validations: {
            oldpassword: [
                [isEmpty, oldpassword],

            ],
            newpassword: [
                [isEmpty, newpassword]
            ],
            confirmpassword: [
                [isEmpty, confirmpassword]
            ]
        }
    }
}

const isEmpty = (value) =>
    value === '' ? lang.signuppopup.isemptyerror : null

class Changepassword extends Component {
    constructor(props) {
        super(props);
    }
    render() {

        const { fields, onChange, onValid, onInvalid, $field, $validation } = this.props
        let errMsgOld, errMsgNew, errMsgConfirm;

        if ($validation.oldpassword.error.reason !== undefined) {
            errMsgOld = ($validation.oldpassword.show && <span className="error">{$validation.oldpassword.error.reason}</span>)
        }
        if ($validation.newpassword.error.reason !== undefined) {
            errMsgNew = ($validation.newpassword.show && <span className="error">{$validation.newpassword.error.reason}</span>)
        }
        if ($validation.confirmpassword.error.reason !== undefined) {
            errMsgConfirm = ($validation.confirmpassword.show && <span className="error">{$validation.confirmpassword.error.reason}</span>)
        }

        return (
            <div className="popup-body">
                <form className="form_sec">
                    <div className="form-group">
                        <div className="focus-out">
                            <label>{lang.changepasswdpopup.current_pwd}</label>
                            <input type="password" className="form-control input-focus old_password" {...$field('oldpassword', (e) => onChange('oldpassword', e.target.value)) } />
                            {errMsgOld}
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="focus-out">
                            <label>{lang.changepasswdpopup.new_pwd}</label>
                            <input type="password" className="form-control input-focus new_password" {...$field('newpassword', (e) => onChange('newpassword', e.target.value)) } />
                            {errMsgNew}
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="focus-out">
                            <label>{lang.changepasswdpopup.reenter_pwd}</label>
                            <input type="password" className="form-control input-focus confirm_password" {...$field('confirmpassword', (e) => onChange('confirmpassword', e.target.value)) } />
                            {errMsgConfirm}
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="login_pop_sub change-pass-sbmt">
                            <button type="button" className="btn btn_black btn_minwid" onClick={(e) => { e.preventDefault(); this.props.$submit(onValid, onInvalid); }}>{lang.changepasswdpopup.submit_btn}</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
Changepassword = validated(validationConfigPassword)(Changepassword)

export default Changepassword;
