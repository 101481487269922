/* eslint-disable */
import React, { Component } from 'react';

import { withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import { languageCd, fbAppId, baseUrl, appId, apiUrl, cateringId, CountryTxt} from "../Helpers/Config";

import { validated } from 'react-custom-validation';
import validator from 'validator';

const lang = require('../Helpers/Language/lang'+'-'+languageCd);

const isEmpty = (value) =>
value === '' ? lang.signuppopup.isemptyerror : null

const isEmail = (email) =>
validator.isEmail(email) ? null : lang.signuppopup.validemail

const minLength = (password, length) =>
    password.length >= length ? null : lang.signuppopup.validepassword

const areSame = (password, rePassword) =>
    password === rePassword ? null : lang.signuppopup.valideconfirmpwd

const isChecked = (terms) => terms ? null : "Please accept terms and conditions"


function validationConfigSignup(props) {

    const { firstname, lastname, email, password, rePassword, mobile, terms } = props.fields

    return {
        fields: ['firstname', 'lastname', 'email', 'password', 'rePassword', 'mobile', 'terms'],

        validations: {
            firstname: [
                [isEmpty, firstname]
            ],
            lastname: [
                [isEmpty, lastname]
            ],
            email: [
               [isEmail, email],
                /* [isUnique, email]*/
            ],
            password: [[minLength, password, 6]],
            rePassword: {
                rules: [[areSame, password, rePassword]],
                fields: ['password', 'rePassword']
            },
            mobile: [
                [isEmpty, mobile],
                [isMobile, mobile],
               /* [isPhonenumerExist, mobile]*/
            ],
            /*terms: [
                [isChecked, terms, 1],
            ],*/
        }
    }
}


const phonenumberPattern = /^[0-9]{6,14}$/;
const isMobile = (mobile) =>
    mobile.match(phonenumberPattern) ? null : lang.signuppopup.validemobile


const isUnique = (email) => {

    var qs = require('qs');
    var postObject = {
        "app_id": appId,
        "type": "web",
        "customer_email": email
    };

    axios.post(apiUrl + "customer/email_exist", qs.stringify(postObject)).then(response => {
        if (response.data.status === "ok") {
            document.getElementById("spn-email-error").innerHTML = '';
        } else {
            document.getElementById("spn-email-error").innerHTML = '<span class="error">'+lang.signuppopup.alreadymail+'</span>';
        }
    });

}

const isPhonenumerExist = (mobile) => {
    var qs = require('qs');
    var postObject = {
        "app_id": appId,
        "type": "web",
        "customer_phone": mobile
    };
    axios.post(apiUrl + "customer/phoneno_exist", qs.stringify(postObject)).then(response => {
        if (response.data.status === "ok") {
            document.getElementById("spn-mobile-error").innerHTML = '';
        } else {
            document.getElementById("spn-mobile-error").innerHTML = '<span class="error">'+lang.signuppopup.alreadymobile+'</span>';
        }
    });

}


class Signup extends Component {

    constructor(props) {
        super(props);

    }

    closepopup(path) {

		const{history} = this.props;
        history.push(path);
        $.magnificPopup.close();
    }

    render() {

        const { fields, onChange, onValid, onInvalid, $field, $validation } = this.props
        const spanStyle = {
            clear: 'both'
        };
        let errMsgFirstName, errMsgLastName, errMsgEmail, errMsgPassword, errMsgrePassword, errMsgMobile, errMsgTerms;

        if ($validation.firstname.error.reason !== undefined) {
            errMsgFirstName = ($validation.firstname.show && <span className="error">{$validation.firstname.error.reason}</span>)
        }
        if ($validation.lastname.error.reason !== undefined) {
            errMsgLastName = ($validation.lastname.show && <span className="error">{$validation.lastname.error.reason}</span>)
        }
        if ($validation.email.error.reason !== undefined) {
            errMsgEmail = ($validation.email.show && <span className="error">{$validation.email.error.reason}</span>)
        }
        if ($validation.password.error.reason !== undefined) {
            errMsgPassword = ($validation.password.show && <span className="error">{$validation.password.error.reason}</span>)
        }
        if ($validation.rePassword.error.reason !== undefined) {
            errMsgrePassword = ($validation.rePassword.show && <span className="error">{$validation.rePassword.error.reason}</span>)
        }
        if ($validation.mobile.error.reason !== undefined) {
            errMsgMobile = ($validation.mobile.show && <span className="error">{$validation.mobile.error.reason}</span>)
        }
		
        {/*if ($validation.terms.error.reason !== undefined) {
            errMsgTerms = ($validation.terms.show && <span className="error">{$validation.terms.error.reason}</span>)
        }*/}


        return (
            <div className="popup-body">
                <h4>{lang.signuppopup.infolabel}</h4>
                <div className="form-group">
                    <div className="focus-out">
                        <label>{lang.signuppopup.firstnamelabel}</label>
                        <input type="text" className="form-control input-focus" value={fields.firstname} {...$field('firstname', (e) => onChange('firstname', e.target.value)) } />
                        {errMsgFirstName}
                    </div>

                </div>
                <div className="form-group">
                    <div className="focus-out">
                        <label>{lang.signuppopup.lastnamelabel}</label>
                        <input type="text" className="form-control input-focus" value={fields.lastname} {...$field('lastname', (e) => onChange('lastname', e.target.value)) } />
                        {errMsgLastName}
                    </div>
                </div>
                <div className="form-group">
                    <div className="focus-out">
                        <label>{lang.signuppopup.mobilenamelabel}</label>
                        {(CountryTxt === 'Indonesia') ? <input type="tel" className="form-control input-focus" maxLength="10" pattern="\d*" value={fields.mobile} {...$field('mobile', (e) => onChange('mobile', e.target.value)) } /> : <input type="tel" className="form-control input-focus" maxLength="8" pattern="\d*" value={fields.mobile} {...$field('mobile', (e) => onChange('mobile', e.target.value)) } />}
                        <div id="spn-mobile-error">{errMsgMobile}</div>


                    </div>
                </div>
				
				{/*<div className="form-group">
						<div className="custom_select">
							<select name="gender" value={fields.gender} className="gender_selct" {...$field('gender', (e) => onChange('gender', e.target.value)) } id='gender' >
							<option value='M'>Male</option>
							<option value='F'>Female</option>
							<option value='O'>Unspecified</option>
							</select>
						</div>
				</div>*/}
				
                <div className="form-group">
                    <h4 className="your_lgn_spacing">{lang.signuppopup.loginlabel}</h4>
                    <div className="focus-out">
                        <label>{lang.signuppopup.emaillabel}</label>
                        <input type="text" className="form-control input-focus" value={fields.email} {...$field('email', (e) => onChange('email', e.target.value)) } />
                        <div id="spn-email-error">{errMsgEmail}</div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="focus-out">
                        <label>{lang.signuppopup.passwordlabel}</label>
                        <input type="password" className="form-control input-focus" value={fields.password} {...$field('password', (e) => onChange('password', e.target.value)) } />
                        {errMsgPassword}
                    </div>
                </div>
                <div className="form-group">
                    <div className="focus-out">
                        <label>{lang.signuppopup.confirmpwdlabel}</label>
                        <input type="password" className="form-control input-focus" value={fields.rePassword} {...$field('rePassword', (e) => onChange('rePassword', e.target.value)) } />
                        {errMsgrePassword}

                    </div>
                </div>
				
                {/*<div className="form-group custom-pdpa-div">
                    <div className="custom_checkbox custom_checkbox_content">
                        <input type="checkbox" id="pdpa_consent" checked={fields.pdpa_consent}   {...$field('pdpa_consent', (e) => onChange("pdpa_consent", e.target.value), null, false) } /> <span></span>
                        <div className="sign_reg">
                            <p>Yes, I have read and accept the <a className="sign_reg_uline" href="javascript:void(0);">PDPA Consent</a>.</p>
                        </div>
                    </div>
                </div>
                
                <div className="form-group">
                    <div className="custom_checkbox custom_checkbox_content">
                        <input type="checkbox" id="terms" checked={fields.terms}   {...$field('terms', (e) => onChange("terms", e.target.value), null, false) } /> <span></span>
                        <div className="sign_reg">
                            <p>I consent to receive coupons and promotions news from Makisan, its franchisees and affiliates, and understand and agree that my information will be used as described here and in Makisan <a className="sign_reg_uline" onClick={(e) => { e.preventDefault(); this.closepopup('/page/privacy-policy') }} href="javascript:void(0);">Privacy Policy</a> and <a className="sign_reg_uline" onClick={(e) => { e.preventDefault(); this.closepopup('/page/terms-and-conditions') }} href="javascript:void(0);">Terms of Service.</a></p>
                        </div>

                    </div>
                    {errMsgTerms}
				</div>*/}
				
                <div className="form-group">
                    <div className="login_pop_sub">

                        <button type="button" className="btn btn_black btn_minwid signup_submit" onClick={(e) => { e.preventDefault(); this.props.$submit(onValid, onInvalid); }}>{lang.signuppopup.submitbtn}</button>
                    </div>
                </div>

            </div>
        );
    }
}
Signup = validated(validationConfigSignup)(Signup)

export default withRouter(Signup);
