/* global google */
import React from "react";
class Autocomplete extends React.Component {
  constructor(props) {
    super(props);
	this.state = { flgTxt: 'no' }
    this.autocompleteInput = React.createRef();
    this.autocomplete = null;
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
  }
  
  componentWillReceiveProps(PropsAdt) {
         if(PropsAdt.flgTxt !== this.state.flgTxt){
			this.autocomplete = new google.maps.places.Autocomplete(
			  this.autocompleteInput.current,
			  { types: ["geocode"], componentRestrictions: {'country': 'id'} }
			);
			this.autocomplete.addListener("place_changed", this.handlePlaceChanged); 
			this.setState({flgTxt: PropsAdt.flgTxt});
         }
  }

  componentDidMount() {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.autocompleteInput.current,
      { types: ["geocode"], componentRestrictions: {'country': 'id'} }
    );
    this.autocomplete.addListener("place_changed", this.handlePlaceChanged);
  }

  handlePlaceChanged() {
    const place = this.autocomplete.getPlace();
    this.props.onPlaceChanged(place);
  }

  render() {
    return (
      <input
        ref={this.autocompleteInput}
        id="autocomplete"
        placeholder={this.props.langArr.takeawaypopup.searchoutletlbl}
        type="text"
      />
    );
  }
}

export default Autocomplete;

